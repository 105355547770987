import back from "./back.png";
import front from "./front.png";
import bottom from "./bottom.png";
import top from "./top.png";
import left from "./left.png";
import right from "./right.png";

export const teegarden2 = {
  back,
  front,
  bottom,
  top,
  left,
  right,
};
