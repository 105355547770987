export default `#version 300 es
precision highp float;
#define GLSLIFY 1

vec3 billboard(vec3 position, mat4 viewMatrix) {
    vec3 cameraRight = vec3(viewMatrix[0][0], viewMatrix[1][0], viewMatrix[2][0]);
    vec3 cameraUp = vec3(viewMatrix[0][1], viewMatrix[1][1], viewMatrix[2][1]);

    return position.x * cameraRight + position.y * cameraUp;
}

in vec2 uv;
in vec3 position;

out vec2 vUv;
out float vDist;

uniform mat4 modelMatrix;
uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;
uniform vec3 cameraPosition;
uniform float uSize;

void main() {
    vec3 worldPosition = billboard(position, viewMatrix) * distance(cameraPosition, modelMatrix[3].xyz) / 60.f * uSize + modelMatrix[3].xyz;
    vec4 viewPosition = viewMatrix * vec4(worldPosition, 1.0f);

    vUv = uv;

    gl_Position = projectionMatrix * viewPosition;
}`