export default `#version 300 es
precision highp float;
#define GLSLIFY 1

struct Light {
    vec4 position;
    vec3 color;
    float intensity;
    bool visible;
};

float blinnPhongSpecular(
  vec3 lightDirection,
  vec3 viewDirection,
  vec3 surfaceNormal,
  float shininess) {

  //Calculate Blinn-Phong power
  vec3 H = normalize(viewDirection + lightDirection);
  return pow(max(0.0, dot(surfaceNormal, H)), shininess);
}

float beckmannDistribution(float x, float roughness) {
  float NdotH = max(x, 0.0001);
  float cos2Alpha = NdotH * NdotH;
  float tan2Alpha = (cos2Alpha - 1.0) / cos2Alpha;
  float roughness2 = roughness * roughness;
  float denom = 3.141592653589793 * roughness2 * cos2Alpha * cos2Alpha;
  return exp(tan2Alpha / roughness2) / denom;
}

float cookTorranceSpecular(
  vec3 lightDirection,
  vec3 viewDirection,
  vec3 surfaceNormal,
  float roughness,
  float fresnel) {

  float VdotN = max(dot(viewDirection, surfaceNormal), 0.0);
  float LdotN = max(dot(lightDirection, surfaceNormal), 0.0);

  //Half angle vector
  vec3 H = normalize(lightDirection + viewDirection);

  //Geometric term
  float NdotH = max(dot(surfaceNormal, H), 0.0);
  float VdotH = max(dot(viewDirection, H), 0.000001);
  float LdotH = max(dot(lightDirection, H), 0.000001);
  float G1 = (2.0 * NdotH * VdotN) / VdotH;
  float G2 = (2.0 * NdotH * LdotN) / LdotH;
  float G = min(1.0, min(G1, G2));
  
  //Distribution term
  float D = beckmannDistribution(NdotH, roughness);

  //Fresnel term
  float F = pow(1.0 - VdotN, fresnel);

  //Multiply terms and done
  return  G * F * D / max(3.14159265 * VdotN, 0.000001);
}

float luma(vec3 color) {
  return dot(color, vec3(0.299, 0.587, 0.114));
}

float luma(vec4 color) {
  return dot(color.rgb, vec3(0.299, 0.587, 0.114));
}

#pragma defines

in vec3 worldPosition;
in vec2 vUv;
in vec3 vTangent;
in vec3 vNormal;

uniform mat4 viewMatrix;
uniform sampler2D tDiffuse;
uniform sampler2D tNormal;
uniform vec3 cameraPosition;
uniform vec3 ambient;
uniform Light lights[16];
uniform samplerCube tEnvMap;

uniform float uMetallic;

#ifdef USE_ROUGHNESS
uniform sampler2D tRoughness;
#else
uniform float uRoughness;
#endif

#ifdef USE_EMISSIVE
uniform sampler2D tEmissive;
#endif

#define EPSILON 0.001f

out vec4 fragData[2];

void main() {
    vec3 tex = max(vec3(EPSILON), pow(texture(tDiffuse, vUv).rgb, vec3(1.f / 2.2f)));  
    vec3 normalMap = texture(tNormal, vUv).rgb * 2.f - 1.f;

    vec3 tangent = vTangent - dot(vTangent, vNormal) * vNormal;
    vec3 bitangent = cross(vNormal, tangent);
    mat3 tbn = mat3(normalize(tangent), normalize(bitangent), normalize(vNormal));

    vec3 eyeDirection = normalize(cameraPosition - worldPosition);
    vec3 diffuse = vec3(0.0f);
    vec3 specular = vec3(0.0f);

    vec3 norm = normalize(tbn * normalMap);

    #ifdef USE_EMISSIVE
    vec3 emissive = texture(tEmissive, vUv).rgb;
    #else
    vec3 emissive = vec3(0.0f);
    #endif

    #ifdef USE_ROUGHNESS
    vec4 mr = texture(tRoughness, vUv);
    // float metallic = mr.r; // TODO: Make blender export metallic too
    float metallic = uMetallic;
    float roughness = mr.g;
    #else
    float metallic = uMetallic;
    float roughness = uRoughness;
    #endif

    vec3 F0 = vec3(0.04f, 0.04f, 0.04f);
    F0 = mix(F0, tex, metallic);

    for(int i = 0; i < lights.length(); i++) {
        if(lights[i].visible) {
            vec3 lightDir = vec3(0.0f);
            float intensity = lights[i].intensity;
            if(lights[i].position.w == 0.0f) {
                lightDir = normalize(-lights[i].position.xyz);
            } else {
                vec3 d = lights[i].position.xyz - worldPosition;
                lightDir = normalize(d);
                float dist = length(d);
                intensity /= 1.0 + dist + pow(dist, 2.0f);
                if(intensity < 0.01f) {
                    continue;
                }
            }
            float diff = max(dot(norm, lightDir), 0.0f);
            vec3 color = lights[i].color * intensity;
            diffuse += diff * color;
            specular += cookTorranceSpecular(lightDir, eyeDirection, norm, roughness, luma(F0)) * color;
        }
    }

    float specularPower = luma(specular);
    if(specularPower < 0.9) {
        specularPower = 0.;
    }

    vec3 reflectedDir = reflect(eyeDirection, norm);
    vec3 reflectionColor = texture(tEnvMap, -reflectedDir).rgb;

    fragData[0] = vec4((diffuse * (1. - metallic) + ambient + specular) * tex + emissive + reflectionColor * metallic * roughness, 1.0f);
    fragData[1].r = luma(emissive) + specularPower / 5.;
}
`