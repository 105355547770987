export default `#version 300 es
precision highp float;
#define GLSLIFY 1

out vec4 fragColor;

uniform vec3 uColor;

void main() {
    fragColor = vec4(uColor, 1.f);
}`