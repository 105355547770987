export default `#version 300 es
precision highp float;
#define GLSLIFY 1

uniform samplerCube tMap;

in vec3 vDir;

out vec4 fragData[2];

void main() {
    fragData[0] = texture(tMap, vDir);
}`