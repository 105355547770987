export default `#version 300 es
precision highp float;
#define GLSLIFY 1

#pragma defines

vec3 billboard(vec3 position, mat4 viewMatrix) {
    vec3 cameraRight = vec3(viewMatrix[0][0], viewMatrix[1][0], viewMatrix[2][0]);
    vec3 cameraUp = vec3(viewMatrix[0][1], viewMatrix[1][1], viewMatrix[2][1]);

    return position.x * cameraRight + position.y * cameraUp;
}

in vec2 uv;
in vec3 position;
in float t;
in vec3 offset;
in vec3 scale;

out vec2 vUv;
out float vT;

uniform mat4 modelMatrix;
uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;

void main() {
    vec4 worldPosition = vec4(billboard(position * scale, viewMatrix) + offset, 1.f);
    #ifdef USE_MODEL_MATRIX
    worldPosition += modelMatrix[3];
    worldPosition.w = 1.f;
    #endif
    vec4 viewPositionPosition = viewMatrix * worldPosition;

    vUv = uv;
    vT = t;

    gl_Position = projectionMatrix * viewPositionPosition;
}`