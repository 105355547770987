export default `#version 300 es
precision highp float;
#define GLSLIFY 1

out vec4 fragData[2];

#define color vec3(1.0, 0.0, 1.0)

void main() {
    fragData[0] = vec4(color, 1.0f);
}`