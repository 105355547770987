export default `precision highp float;
#define GLSLIFY 1

#define texture2D_0 texture2D

// @source https://github.com/Erkaman/glsl-godrays/blob/master/index.glsl

vec3 godrays(
    float density,
    float weight,
    float decay,
    float exposure,
    int numSamples,
    sampler2D occlusionTexture,
    vec2 screenSpaceLightPos,
    vec2 uv
) {

    vec3 fragColor = vec3(0.0, 0.0, 0.0);

    vec2 deltaTextCoord = uv - screenSpaceLightPos;

    vec2 textCoo = uv.xy;
    deltaTextCoord *= (1.0 / float(numSamples)) * density;
    float illuminationDecay = 1.0;

    for(int i = 0; i < 100; i++) {
        if(numSamples < i) {
            break;
        }

        textCoo -= deltaTextCoord;
        vec3 samp = texture2D_0(occlusionTexture, textCoo).xyz;
        samp *= illuminationDecay * weight;
        fragColor += samp;
        illuminationDecay *= decay;
    }

    fragColor *= exposure;

    return fragColor;

}

uniform sampler2D tMap;
uniform sampler2D tBloom;
uniform vec2 uSunPos;
uniform float uDensity;
uniform float uWeight;
uniform float uDecay;
uniform float uExposure;

varying vec2 vUv;

void main() {
    vec4 godraysLayer = vec4(godrays(uDensity, uWeight, uDecay, uExposure, 20, tBloom, uSunPos, vUv), 1.0);
    gl_FragColor = texture2D(tMap, vUv) + godraysLayer;
}`